<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de suscriptores</h3> <small> (Hay {{ totalSubscribers }} suscriptores)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-end">
              <flat-pickr
                v-model="filterDate"
                class="form-control mr-1"
                :config="{ enableTime: false, dateFormat: 'Y-m',locale: locale}"
              />
              <b-button
                v-can-permissions="'Exportar suscriptores'"
                variant="primary"
                class="mr-1"
                @click="downloadSubscriber"
              >
                <span class="text-nowrap">Exportar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchSubscribers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      />
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalSubscribers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import subscriberHandler from '@/views/dramox/subscriber/subscriberHandler'
import subscriberStoreModule from '@/views/dramox/subscriber/subscriberStoreModule'
import flatPickr from 'vue-flatpickr-component'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es.js'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    flatPickr,
  },
  data() {
    return {
      locale: SpanishLocale,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-subscribers'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, subscriberStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const filterDate = ref('')

    const downloadSubscriber = () => {
      store.dispatch('app-subscribers/downloadSubscriber', { date: filterDate.value })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'suscriptores.xlsx')
          document.body.appendChild(link)
          link.click()
          // showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(e => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const {
      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalSubscribers,
      perPageOptions,
      searchQuery,
      refUserListTable,
      refetchData,
      fetchSubscribers,
      // UI
    } = subscriberHandler()

    return {

      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalSubscribers,
      perPageOptions,
      searchQuery,
      refUserListTable,
      refetchData,
      fetchSubscribers,

      downloadSubscriber,
      filterDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
