import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function subscriberHandler() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'created_at', label: 'Fecha Registro', sortable: false },
    { key: 'subscriber', label: 'Nombre', sortable: false },
    { key: 'mobile', label: 'Celular', sortable: false },
    { key: 'email', label: 'Email', sortable: false },
  ]
  const perPage = ref(10)
  const totalSubscribers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]


  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchSubscribers = (ctx, callback) => {
    store
      .dispatch('app-subscribers/fetchSubscribers', {
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalSubscribers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  return {
    fetchSubscribers,
    tableColumns,
    perPage,
    currentPage,
    totalSubscribers,
    perPageOptions,
    refUserListTable,
    refetchData,
  }
}
